<template>
  <h2>ABOUT</h2>
  <div class="content" style="height:80vh">
    <div class="imagecontainer" id="about">
          <div><img src="../assets/me.jpg"></div>
          <div>
            <p style="margin:0;">Zachariah "Zach" Watson is a web developer and artist from Kansas City, MO. He's well versed in the Adobe Creative Cloud and a multitude of programming languages including CSS, 
              HTML, JavaScript, Python, as well as JavaScript frameworks such as Vue.js, Node.js, and Express.js. He has around three years of experience in working with website 
              clients thanks to his time at Northwest Missouri State University. In his free time he likes to play the drums/guitar and code creatively using p5.js and GLSL.</p><br><br>
            <h2 style="margin:0;text-align:left;">CONTACT ME</h2><br>
            <p style="margin:0;">Email: watsonzachariah@gmail.com</p><br>
            <p style="margin:0;">Instagram: @zchwtsn</p><br>
            <p style="margin:0;">Twitter: @zchwtsn</p><br><br>
            <h2 style="margin:0;text-align:left;">THE WEBSITE</h2><br>
            <p style="margin:0;">Coded from scratch using Vue.js</p><br>
            <p style="margin:0;">Background coded from scratch using p5.js</p><br>
            </div>
      </div>
  </div>
</template>
